import CarsTableDamageCell from '@components/cars-table-next/cells/DamageCell';
import { useCarUpdate } from '@components/cars-table-next/useCarUpdate';
import { Activity, ActivityList, DocView, DocViewTitle, DocViewTrigger } from '@components/ui';
import { Tooltip } from '@material-ui/core';
import { green, grey, orange, red } from '@material-ui/core/colors';
import { get, meanBy } from 'lodash';
import { ChangeEventHandler, useRef } from 'react';
import { OverlayContainer, useButton, useOverlayPosition, useOverlayTrigger } from 'react-aria';
import { Trans, useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from 'react-stately';
import { getActivities } from 'src/modules/activity';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, IconType, fallbackIcon, getIcon } from 'src/modules/data';
import {
  ConditionalFormattingType,
  CountryCode,
  FirstCallType,
  MonetaryAmount,
  NormalizedColor,
  NormalizedFuel,
  SourceType,
  ValidityType,
} from 'src/modules/generated/api';
import { Option, tr } from 'src/modules/i18n-helpers';
import {
  colors,
  countryCodeToOptions,
  engineFuels,
  equipments,
  getLabel,
  promotions,
  sourceOriginFilter,
  sources,
  specialHighlights,
} from 'src/modules/labels';
import i18n from 'src/setup/i18n';
import theme from 'src/setup/theme';
import { Potential } from 'src/types/potential';

import { lighten } from 'polished';
import { useUser } from '../../../hooks/useUser';
import { ActivityType } from '../../../modules/activity';
import { SupportedCurrencyCode, makeMonetaryAmount } from '../../../modules/currency';
import humanizeDurationInstance from '../../../modules/humanize-duration';
import UserRoles from '../../../types/UserRoles';
import {
  AvatarGroupCell,
  LeadIconCell,
  NumericCell,
  NumericCellFallback,
  RatingCell,
  TextCell,
  TextCellFallback,
  TimestampCell,
  getAvatarCount,
} from '../cells';
import { CarImage } from '../cells/CarImage';
import { TrendCell } from '../cells/TrendCell';
import { ColumnSize, ExportType } from '../constants';
import { currencyFormat, formatCO2, formatMileage, percentFormat } from '../format';
import { isDefined } from '../lib';
import type { BidsTableNextItem, CarsTableNextItem, ExportDef, StrictColumnDef } from '../types';
import { IconGroup } from './IconGroup';
import { bidColumns } from './bid-columns';
import { createPotentialColumn } from './common/templates';
import { externalColumns } from './external-columns';
import { taxColumns } from './tax-columns';

// TODO: bind column-meta to specific table scope
// TODO: format props (codemod)

const getMinutesFromNow = (date: string | number | Date): number => (Date.now() - +new Date(date)) / 1000 / 60;

const getMonthsFromNow = (date: string | number | Date): number => getMinutesFromNow(date) / 60 / 24 / 30.437;

// gets disabled if any conditional formatting matches
const highlightCellStyle = {
  '--text_cell__text_color___text': orange[900],
  '--text_cell__text_color___text_solo': orange[900],
  fontWeight: 'bold',
};

const visualExportTypeOptions: ExportDef['typeOptions'] = [
  { value: ExportType.textAndIcons, label: 'carsTableNext.export.exportTypes.textAndIcons' },
  { value: ExportType.textOnly, label: 'carsTableNext.export.exportTypes.textOnly' },
  { value: ExportType.iconsOnly, label: 'carsTableNext.export.exportTypes.iconsOnly' },
];

const optionsToExportValueTable = (options: Readonly<Option[]>) =>
  options.map((option) => ({ name: option.value, label: tr(option.label) }));

export const columns = (enableSorting: boolean = true): StrictColumnDef<CarsTableNextItem>[] => [
  {
    id: 'car',
    accessorKey: 'carId',
    cell: (props) => (
      <LeadIconCell
        text={props.row.original?.model}
        supportText={props.row.original?.vin}
        startDate={props.row.original.dateStart}
        endDate={props.row.original.dateEnd}
        icon={
          <CarImage
            bidStateInfo={props.row.original?.bidStateInfo}
            imageThumbnailLink={props.row.original?.imageThumbnailLink}
            expired={props.row.original?.validity === ValidityType.Expired}
          />
        }
      />
    ),
    size: ColumnSize.lg,
    meta: {
      fallback: () => <TextCellFallback textWidth="80%" supportTextWidth="50%" withStartAdornment />,
      export: {
        exports: [
          {
            key: 'imageLinks',
            label: 'carsTableNext.car.imageLink_plural',
          },
          {
            key: 'model',
            label: 'carsTableNext.car.model',
          },
          {
            key: 'normalized.brand',
            label: 'carsTableNext.car.normalized.brand',
          },
          {
            key: 'normalized.equipmentLine',
            label: 'carsTableNext.car.normalized.equipmentLine',
          },
          {
            key: 'normalized.version',
            label: 'carsTableNext.car.normalized.version',
          },
          {
            key: 'vin',
            label: 'carsTableNext.car.vin',
          },
          {
            key: 'carId',
            label: 'carsTableNext.car.carId',
          },
        ],
      },
      conditionalFormat: {
        key: ConditionalFormattingType.Model,
        getValue: ({ row }) => row.original.model,
      },
      tableCellProps: ({ row }) => ({
        style: {
          background: row.original.isNew ? '#b8e0b9' : undefined,
          transition: 'background-color 300ms ease-in-out',
        },
      }),
    },
    enableSorting: false,
  },
  {
    id: 'rating',
    accessorKey: 'rating',
    cell: (props) => {
      const {
        getValue,
        row: { original },
      } = props;
      const { t } = useTranslation();
      const { mutate: updateCar } = useCarUpdate();
      const overlayState = useOverlayTriggerState({});

      const triggerRef = useRef<HTMLDivElement>(null);
      const overlayRef = useRef(null);

      const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, overlayState, triggerRef);

      const { buttonProps } = useButton(triggerProps, triggerRef);

      const { overlayProps: positionProps } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef,
        placement: 'right top',
        offset: 6,
        isOpen: overlayState.isOpen,
      });

      const handleChangeRating: ChangeEventHandler<{}> = (event) => {
        const { value } = event.target as HTMLInputElement;
        const rating = parseInt(value, 10);
        updateCar({ ...original, rating: rating === original.rating ? 0 : rating });
      };

      const activities = getActivities(original.individualizations ?? []);
      const ratings = activities.filter((activity) => activity.type === ActivityType.rating);

      return (
        <>
          {activities.length > 0 && (
            <DocViewTrigger
              {...buttonProps}
              ref={triggerRef}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            />
          )}
          {overlayState.isOpen && (
            <OverlayContainer>
              <DocView
                {...overlayProps}
                {...positionProps}
                ref={overlayRef}
                title={({ titleProps }) => (
                  <DocViewTitle {...titleProps} gutterBottom>
                    {t('carsTableNext.cells.rating.externalDocView.title')}
                  </DocViewTitle>
                )}
                isOpen={overlayState.isOpen}
                onClose={overlayState.close}
              >
                <ActivityList spacing={2}>
                  {activities.map((activity) => (
                    <Activity key={activity.id} {...activity} createdAt={activity.createdAt ?? '-'} />
                  ))}
                </ActivityList>
              </DocView>
            </OverlayContainer>
          )}
          <RatingCell
            value={getValue<CarsTableNextItem['rating']>() ?? 0}
            supportText={original.userComment}
            onChange={handleChangeRating}
            style={{
              pointerEvents: 'all',
            }}
            name={`rating-${original.carId}`}
            comparisonRating={ratings.length > 0 && meanBy(ratings, (x) => x.value)}
            comparisonRatingCount={ratings.length}
          />
        </>
      );
    },
    size: ColumnSize.sm,
    enableSorting,
    meta: {
      // prevent row-click & focus
      tableCellProps: {
        onClick: (event) => event.stopPropagation(),
        style: {
          cursor: 'default',
        },
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
      filter: ['ratingMin', 'anyOtherUserRatingMin', 'hasUserComment', 'hasAnyOtherUserComment'],
      export: {
        exports: [
          {
            key: 'rating',
            label: 'carsTableNext.car.rating',
          },
          {
            key: 'userComment',
            label: 'carsTableNext.car.userComment',
          },
        ],
      },
    },
  },
  {
    id: 'source',
    accessorKey: 'source',
    cell: (props) => (
      <TextCell
        text={getLabel(sources, props.getValue<CarsTableNextItem['source']>() as string) ?? '-'}
        supportText={
          <IconGroup
            call={props.row.original.firstCallType}
            virtual={props.row.original.virtualSources as SourceType[]}
          />
        }
      />
    ),
    size: ColumnSize.xs,
    meta: {
      filter: ['source'],
    },
    enableSorting,
  },
  {
    id: 'originSource',
    accessorKey: 'originSource',
    cell: (props) => (
      <TextCell
        text={getLabel(sourceOriginFilter, props.getValue<CarsTableNextItem['originSource']>() as string) ?? '-'}
      />
    ),
    size: ColumnSize.md,
    meta: {
      filter: ['originSource'],
      roles: [UserRoles.ADMIN],
      columnVisible: false,
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting,
  },
  {
    id: 'dateStart',
    accessorKey: 'dateStart',
    cell: (props) => <TimestampCell timestamp={props.getValue<CarsTableNextItem['dateStart']>()} />,
    size: ColumnSize.xs,
    meta: {
      fallback: () => <TextCellFallback textWidth="80%" supportTextWidth="60%" />,
      filter: ['startDateFilter'],
    },
  },
  {
    id: 'dateEnd',
    accessorKey: 'dateEnd',
    cell: (props) => <TimestampCell timestamp={props.getValue<CarsTableNextItem['dateEnd']>()} />,
    size: ColumnSize.xs,
    meta: {
      fallback: () => <TextCellFallback textWidth="80%" supportTextWidth="60%" />,
      filter: ['endDateFilter'],
    },
  },
  {
    id: 'highlights',
    cell: ({ row: { original }, column }) => {
      const { data: user } = useUser();

      return (
        <AvatarGroupCell
          AvatarProps={{
            imgProps: {
              style: {
                // strip icon border
                width: 'calc(100% + 2px)',
                height: 'calc(100% + 2px)',
              },
            },
          }}
          items={[
            !!original.assessmentLink && {
              key: 'assessmentLink',
              src: '/images/assessments/default.svg',
              component: 'a',
              href: original.assessmentLink,
              target: '_blank',
              style: { borderColor: theme.palette.primary.main },
              helpText: 'carsTableNext.car.assessmentLink',
            },
            !!original.colorId && {
              key: 'colorId',
              src:
                original.colorId !== `${NormalizedColor.Unknown}`
                  ? getIcon(IconType.COLOR, original.colorId)
                  : fallbackIcon,
              helpText: getLabel(colors, original.color ?? original.colorId),
            },
            !!original.countryOrigin && {
              key: 'countryOrigin',
              src: getIcon(IconType.COUNTRY, original.countryOrigin),
              helpText: 'carsTableNext.car.countryOrigin',
            },
            !!original.promotions?.includes('THERMO') && {
              key: 'promotions',
              src: getIcon(IconType.SPECIAL, 'THERMO'),
              helpText: getLabel(specialHighlights, 'THERMO'),
              style: {
                background: red[200],
              },
            },
            !!original.virtualSources &&
              original.virtualSources.includes(SourceType.Vvj) && {
                key: 'sq1',
                src: getIcon(IconType.VIRTUAL_SOURCE, SourceType.Vvj),
                helpText: getLabel(sources, SourceType.Vvj),
              },
            !!original.firstCallType && {
              key: 'firstCallType',
              src: getIcon(
                IconType.VIRTUAL_SOURCE,
                original.firstCallType === FirstCallType.Own
                  ? SourceType.RealFirstCallOwn
                  : SourceType.RealFirstCallOther,
              ),
              helpText: getLabel(
                sources,
                original.firstCallType === FirstCallType.Own
                  ? SourceType.RealFirstCallOwn
                  : SourceType.RealFirstCallOther,
              ),
            },
            !!original.promotions?.includes('FOKUS_MODELL') && {
              key: 'promotions',
              src: getIcon(IconType.PROMOTION, 'FOKUS_MODELL'),
              helpText: getLabel(promotions, 'FOKUS_MODELL'),
            },
            !!original.promotions?.includes('BONUS_MODELL') &&
              user?.dealer?.country !== CountryCode.De && {
                key: 'promotions',
                src: getIcon(IconType.PROMOTION, 'BONUS_MODELL'),
                helpText: getLabel(promotions, 'BONUS_MODELL'),
              },
            !!original.promotions?.includes('HEISSW_AKTION') && {
              key: 'promotions',
              src: getIcon(IconType.PROMOTION, 'HEISSW_AKTION'),
              helpText: getLabel(promotions, 'HEISSW_AKTION'),
            },
            ...(original.equipmentKeys?.map((equipmentKey: string) => ({
              key: equipmentKey,
              src: getIcon(IconType.EQUIPMENT, equipmentKey),
              helpText: getLabel(equipments, equipmentKey),
              style: {
                background: equipmentKey === 'WARRANTY_EXTENSION' ? green[200] : undefined,
              },
            })) ?? []),
          ].filter(isDefined)}
          max={getAvatarCount(column.getSize(), 2)}
        />
      );
    },
    size: ColumnSize.md,
    meta: {
      export: {
        key: 'equipmentKeys',
        typeOptions: visualExportTypeOptions,
        exports: [
          {
            key: 'countryOrigin',
            label: 'carsTableNext.car.countryOrigin',
            valueLabels: optionsToExportValueTable(
              countryCodeToOptions(COUNTRY_ORIGIN_FILTER_COUNTRIES, i18n.language).sort((a, b) =>
                a.label.localeCompare(b.label, i18n.language),
              ),
            ),
          },
          {
            key: 'colorId',
            label: 'carsTableNext.car.colorId',
          },
          {
            key: 'equipmentKeys',
            label: 'carsTableNext.car.equipmentKeys',
            valueLabels: optionsToExportValueTable(equipments),
          },
          {
            key: 'assessmentLink',
            label: 'carsTableNext.car.assessmentLink',
          },
          {
            key: 'firstCallType',
            label: 'carsTableNext.car.firstCallType',
          },
          {
            key: 'virtual_source',
            label: 'carsTableNext.car.virtualSource',
          },
        ],
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
      filter: ['equipments'],
    },
    enableSorting: false,
  },
  {
    id: 'firstRegistration',
    accessorKey: 'firstRegistration',
    cell: (props) => (
      <TimestampCell
        timestamp={props.getValue<CarsTableNextItem['firstRegistration']>()}
        format="MM/yy"
        displayDiff={false}
      />
    ),
    minSize: ColumnSize.xxs,
    size: ColumnSize.xxs,
    meta: {
      fallback: () => <NumericCellFallback textWidth="70%" />,
      filter: ['registrationDate'],
      conditionalFormat: {
        key: ConditionalFormattingType.FirstRegistration,
        getValue: ({ row }) => row.original.firstRegistration && getMonthsFromNow(row.original.firstRegistration),
      },
    },
  },
  {
    id: 'mileage',
    accessorKey: 'mileage',
    cell: (props) => (
      <NumericCell value={props.getValue<CarsTableNextItem['mileage']>() ?? '-'} format={formatMileage} />
    ),
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      filter: ['mileage'],
      conditionalFormat: ConditionalFormattingType.Mileage,
    },
    size: ColumnSize.xs,
  },
  {
    id: 'promotions',
    accessorKey: 'promotions',
    cell: (props) => (
      <AvatarGroupCell
        items={
          props
            .getValue<CarsTableNextItem['promotions']>()
            ?.filter((promo) => promo !== 'THERMO')
            .map((promotionKey: string) => ({
              key: promotionKey,
              src: getIcon(IconType.PROMOTION, promotionKey),
              imgProps: {
                style: {
                  // strip icon border
                  width: 'calc(100% + 2px)',
                  height: 'calc(100% + 2px)',
                },
              },
              helpText: getLabel(promotions, promotionKey),
              alt: 'Promotion',
            })) ?? []
        }
        max={getAvatarCount(props.column.getSize(), 2)}
      />
    ),
    meta: {
      export: {
        typeOptions: visualExportTypeOptions,
        valueLabels: optionsToExportValueTable(promotions),
      },
      columnVisible: false,
      fallback: () => <TextCellFallback textWidth="80%" />,
      filter: ['promotions'],
      conditionalFormat: ConditionalFormattingType.Promotions,
    },
    enableSorting: false,
  },
  {
    id: 'priceDamage',
    accessorKey: 'priceDamage',
    cell: (props) => {
      const {
        getValue,
        column,
        row: { original },
      } = props;
      const value = getValue<CarsTableNextItem['priceDamage']>();
      return (
        <CarsTableDamageCell
          car={original}
          maxIcons={getAvatarCount(column.getSize(), 1)}
          priceDamage={makeMonetaryAmount(value?.value ?? 0, value?.unit as SupportedCurrencyCode)}
        />
      );
    },
    meta: {
      conditionalFormat: [
        { key: ConditionalFormattingType.PriceDamage, getValue: (cell) => cell.row.original.priceDamage },
        { key: ConditionalFormattingType.DamageType, getValue: (cell) => cell.row.original.damageType },
      ],
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      filter: ['priceDamage'],
    },
  },
  {
    id: 'normalized.fuel',
    accessorFn: (car) => car?.normalized?.fuel,
    cell: ({ getValue }) => <TextCell text={getLabel(engineFuels, getValue<NormalizedFuel>() || '-')} />,
    meta: {
      conditionalFormat: ConditionalFormattingType.NormalizedFuel,
      fallback: () => <TextCellFallback textWidth="80%" />,
      filter: ['normalizedFuel'],
      columnVisible: false,
    },
    enableSorting,
  },
  {
    id: 'engineCo2Nedc',
    accessorKey: 'engineCo2Nedc',
    cell: (props) => {
      const {
        getValue,
        row: { original },
      } = props;
      const value = getValue<CarsTableNextItem['engineCo2Nedc']>();
      return (
        <NumericCell
          value={value ?? '-'}
          format={(v) => formatCO2(v, !!original.co2ApproximationMethod)}
          helperText={!!original.co2ApproximationMethod && !!value && 'carsTable.hints.approxCo2'}
        />
      );
    },
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      filter: ['engineCo2Nedc'],
      conditionalFormat: ConditionalFormattingType.Co2Nefz,
    },
    enableSorting,
  },
  {
    id: 'engineCo2Wltp',
    accessorKey: 'engineCo2Wltp',
    cell: (props) => {
      const {
        getValue,
        row: { original },
      } = props;
      const value = getValue<CarsTableNextItem['engineCo2Wltp']>();
      return (
        <NumericCell
          value={value ?? '-'}
          format={(v) => formatCO2(v, !!original.co2ApproximationMethod)}
          helperText={!!original.co2ApproximationMethod && !!value && 'carsTable.hints.approxCo2'}
        />
      );
    },
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      filter: ['engineCo2Wltp'],
      conditionalFormat: ConditionalFormattingType.Co2Wltp,
    },
    enableSorting,
  },

  ...taxColumns,

  {
    id: 'onlineTimeInMinutes',
    accessorKey: 'onlineTimeInMinutes',
    cell: (props) => {
      const { getValue } = props;
      const value = getValue<CarsTableNextItem['onlineTimeInMinutes']>();
      return <TextCell text={value ? humanizeDurationInstance(value * 60 * 1000) : '-'} />;
    },
    meta: {
      conditionalFormat: ConditionalFormattingType.DateOnlineTimeInMinutes,
      fallback: () => <TextCellFallback textWidth="80%" />,
      roles: [UserRoles.ADMIN],
      columnVisible: false,
    },
    size: ColumnSize.xs,
    enableSorting,
  },
  {
    id: 'numAuctions',
    accessorKey: 'numAuctions',
    cell: (props) => <NumericCell value={props.getValue<CarsTableNextItem['numAuctions']>() || '-'} />,
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="2ch" />,
      conditionalFormat: ConditionalFormattingType.NumAuctions,
      filter: ['numAuctions'],
    },
    size: ColumnSize.xs,
  },
  {
    id: 'marketingChannel',
    accessorKey: 'marketingChannel',
    cell: (props) => {
      const { t } = useTranslation();

      return (
        <TextCell
          text={props
            .getValue<CarsTableNextItem['marketingChannel']>()
            ?.map((channel) => t(`carsTableNext.filter.marketingChannels.${channel}` as any) as string)
            .join(', ')}
        />
      );
    },
    meta: {
      filter: ['marketingChannel'],
    },
    enableSorting,
  },
  {
    id: `potential.${Potential.base}`,
    accessorFn: (car) => get(car, ['potential', Potential.base]),
    cell: (props) => (
      <NumericCell
        value={props.getValue<MonetaryAmount | number | undefined>() ?? '-'}
        // format only receives percentage formats as MonetaryAmounts are resolved by NumericCell
        format={percentFormat.format}
        supportText={props.row.original.marketingChannel?.reduce<JSX.Element[]>(
          (res, channel, index) => [
            ...res,
            <Tooltip
              key={channel}
              title={
                <Trans
                  i18nKey={`carsTableNext.car.marketingChannel.${channel}`}
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                  }}
                />
              }
              arrow
            >
              <span>
                <span
                  style={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                    cursor: 'help',
                  }}
                >
                  <Trans i18nKey={`carsTableNext.car.marketingChannel.${channel}_abbr`} />
                </span>
                {props.row.original.marketingChannel?.at(index + 1) ? ', ' : null}
              </span>
            </Tooltip>,
            <TrendCell
              key={`1-${channel}`}
              marketingChannel={props.row.original?.marketingChannel || []}
              prevAuctions={props.row.original.previousAuctions || []}
              currPrice={props.row.original.potential?.base?.amount}
              potential={props.row.original.previousAuctions?.map((amount) => amount.price?.base as MonetaryAmount)}
            />,
          ],
          [],
        )}
      />
    ),
    meta: {
      isNumeric: true,
      tableCellProps: {
        style: highlightCellStyle,
        onClick: (event) => event.stopPropagation(),
      },
      conditionalFormat: ConditionalFormattingType.PotentialBase,
      // FIXME: remove sortKey alias @BE
      sortKey: 'priceOrientation',
      fallback: () => <NumericCellFallback textWidth="80%" supportTextWidth="4ch" />,
      export: {
        exports: [
          {
            key: 'potential.base',
            label: 'carsTableNext.car.potential.base',
          },
          {
            key: 'priceFixed',
            label: 'carsTableNext.car.priceFixed',
          },
          {
            key: 'priceOrientation',
            label: 'carsTableNext.car.priceOrientation',
          },
        ],
      },
    },
    enableSorting,
  },
  createPotentialColumn(
    Potential.purchaseNetWholesaleUnrepaired,
    {
      columnVisible: true,
      conditionalFormat: ConditionalFormattingType.PurchaseNetUnrepaired,
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.purchaseNetRetailRepaired,
    {
      columnVisible: true,
      conditionalFormat: ConditionalFormattingType.PurchaseNetRepaired,
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.purchaseGross,
    {
      columnVisible: true,
      tableCellProps: {
        style: {
          fontWeight: 'bold',
          background: lighten(0.005, grey[50]),
        },
      },
      conditionalFormat: ConditionalFormattingType.PurchaseGross,
      isTableHeaderHighlighted: true,
      filter: ['purchasePriceGross'],
    },
    enableSorting,
  ),
  {
    id: 'recommendedRetailPrice',
    accessorKey: 'recommendedRetailPrice',
    cell: (props) => (
      <NumericCell
        value={props.getValue<CarsTableNextItem['recommendedRetailPrice']>() ?? '-'}
        format={currencyFormat.format}
      />
    ),
    meta: {
      isNumeric: true,
      columnVisible: false,
      fallback: () => <NumericCellFallback textWidth="80%" />,
    },
    enableSorting,
  },
  {
    id: 'recommendedRetailPriceDeduction',
    cell: ({ row: { original } }) => (
      <NumericCell
        value={original.recommendedRetailPriceDeductionAbsolute ?? '-'}
        supportText={
          original.recommendedRetailPriceDeductionRelative &&
          percentFormat.format(original.recommendedRetailPriceDeductionRelative)
        }
        format={currencyFormat.format}
      />
    ),
    meta: {
      isNumeric: true,
      columnVisible: false,
      fallback: () => <NumericCellFallback textWidth="80%" supportTextWidth="4ch" />,
    },
    enableSorting,
  },
  ...externalColumns(enableSorting),
];

export const columnsWithBids: StrictColumnDef<CarsTableNextItem | BidsTableNextItem>[] = [
  ...columns(false).slice(0, 9),
  ...bidColumns,
  ...columns(false).slice(9),
];
