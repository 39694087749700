import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import { SupportedLanguage } from 'humanize-duration';
import countries from 'i18n-iso-countries';
import i18n from 'i18next';
import { takeRight } from 'lodash';
import UserRole from 'src/types/UserRoles';
import { Tax } from '../types/tax';
import {
  BidFilterStatus,
  BidSuccessStatus,
  BidVisibility,
  BuyableStatus,
  CarDetailing,
  ConditionalFormattingScope,
  ConditionalFormattingType,
  DamageType,
  DateFilter,
  DeliveryAddressDTO,
  DeliveryAddressType,
  MarketingChannel,
  NormalizedFuel,
  NormalizedTransmission,
  OfferType,
  PriceType,
  SearchAgentRole,
  SourceType,
  TaxType,
  TermsConditionsType,
  UsernameStatusBasic,
  ValidityType,
  ValuationCountryCode,
} from './generated/api';
import { Option, tr } from './i18n-helpers';
import { CARS_BIDS_TABLE_COLUMNS, CarTableColumn } from './table-data';
import { SUPPORTED_COUNTRIES } from './valuation-country-helpers';

export const getLabel = (options: Readonly<Option[]>, value: string): string => {
  const option = options.find(({ value: itemValue }) => itemValue === value);
  return option ? tr(option.label, option.options) : value;
};

export const colors: Readonly<Option[]> = [
  { value: 'BG', label: 'colors.beige' },
  { value: 'BL', label: 'colors.blue' },
  { value: 'BR', label: 'colors.brown' },
  { value: 'GE', label: 'colors.yellow' },
  { value: 'GN', label: 'colors.green' },
  { value: 'GO', label: 'colors.gold' },
  { value: 'GR', label: 'colors.grey' },
  { value: 'OR', label: 'colors.orange' },
  { value: 'RT', label: 'colors.red' },
  { value: 'SI', label: 'colors.silver' },
  { value: 'SW', label: 'colors.black' },
  { value: 'WS', label: 'colors.white' },
];

export const highlightLabels: Readonly<Option[]> = [
  { value: 'COLOR', label: 'colors.color' },
  { value: 'COUNTRY', label: 'car.countryOrigin' },
  { value: 'EQUIPMENT', label: 'car.equipment' },
  { value: 'ASSESSMENT', label: 'common.assessment' },
  { value: 'DAMAGE_TYPE', label: 'car.damage' },
  { value: 'VIRTUAL_SOURCE', label: 'common.virtual_source' },
];

export const marketingChannel: Readonly<Option[]> = [
  {
    label: 'carsTableNext.filter.marketingChannels.FIXED',
    value: MarketingChannel.Fixed,
  },
  {
    label: 'carsTableNext.filter.marketingChannels.BID',
    value: MarketingChannel.Bid,
  },
];

export const equipments: Readonly<Option[]> = [
  { value: 'AIRSUSP', label: 'car.equipments.adaptiveChassis' },
  { value: 'AWD', label: 'car.equipments.allWheel' },
  { value: 'ALU', label: 'car.equipments.aluWheel_plural' },
  { value: 'AHK', label: 'car.equipments.towCoupling' },
  { value: 'WARRANTY_EXTENSION', label: 'car.equipments.warrantyExt' },
  { value: 'ASSI', label: 'car.equipments.assistance' },
  { value: 'AUT', label: 'car.equipments.automatic' },
  { value: 'BUSINESS', label: 'car.equipments.businessPackage' },
  { value: 'COMFORT', label: 'car.equipments.comfort' },
  { value: 'FRLINE', label: 'car.equipments.frline' },
  { value: 'INFOTAINMENT', label: 'car.equipments.infotainment' },
  { value: 'AC', label: 'car.equipments.airCond' },
  { value: 'ACAUTO', label: 'car.equipments.autoAirCond' },
  { value: 'LEDER', label: 'car.equipments.leather' },
  { value: 'TRUCKREG', label: 'car.equipments.truckPerm' },
  { value: 'NAVI', label: 'car.equipments.navSys' },
  { value: 'PDC', label: 'car.equipments.parkDistCont' },
  { value: 'RAD2', label: 'car.equipments.rad2' },
  { value: 'REIFEN2', label: 'car.equipments.reifen2' },
  { value: 'RLINE', label: 'car.equipments.rLine' },
  { value: 'REARCAM', label: 'car.equipments.rearCam' },
  { value: 'SLINE', label: 'car.equipments.sLine' },
  { value: 'ESD', label: 'car.equipments.sunPanRoof' },
  { value: 'SHZ', label: 'car.equipments.seatHeater' },
  { value: 'SONDER', label: 'car.equipments.specialInstallation' },
  { value: 'SOUND', label: 'car.equipments.soundSystem' },
  { value: 'SPORTLINE', label: 'car.equipments.sportline' },
  { value: 'SPORTS_PACKAGE', label: 'car.equipments.sports_package' },
  { value: 'STDHZG', label: 'car.equipments.stationaryHeating' },
  { value: 'TEL', label: 'car.equipments.speakerphone' },
  { value: 'GRA', label: 'car.equipments.cruiseControl' },
  { value: 'HEAT_PUMP', label: 'car.equipments.heatPump' },
  { value: 'WINTER', label: 'car.equipments.winterPackage' },
  { value: 'XENON', label: 'car.equipments.xenon' },
];

export const specialHighlights: Readonly<Option[]> = [{ value: 'THERMO', label: 'car.equipments.thermo' }];

export const damageTypes: Readonly<Option[]> = [
  { value: DamageType.Hail, label: 'car.damageTypes.hail' },
  { value: DamageType.Accident, label: 'car.damageTypes.accident' },
  { value: DamageType.Dirt, label: 'car.damageTypes.dirt' },
  { value: DamageType.MissingWheelSet, label: 'car.damageTypes.missingWheelset' },
  { value: DamageType.RepairedDamage, label: 'car.damageTypes.repairedDamage' },
  { value: DamageType.HighDamage, label: 'car.damageTypes.highDamage' },
  { value: DamageType.Refurbished, label: 'car.damageTypes.refurbished' },
];

export const taxationTypes: Readonly<Option[]> = [
  { value: TaxType.Regelbesteuert, label: 'carsTable.regularilyTaxed' },
  { value: TaxType.Differenzbesteuert, label: 'carsTable.differentialTaxation' },
];

export const taxes: Readonly<Option<Tax>[]> = [
  { value: Tax.VAT, label: 'tax.VAT' },
  { value: Tax.NOVA, label: 'tax.NOVA' },
  { value: Tax.REGISTRATION, label: 'tax.REGISTRATION' },
  { value: Tax.ECO, label: 'tax.ECO' },
  { value: Tax.ECOMALUS, label: 'tax.ECOMALUS' },
  { value: Tax.TRANSCRIPTION, label: 'tax.TRANSCRIPTION' },
  // { value: 'AUTHENTICATION', label: 'tax.AUTHENTICATION' },
  // { value: 'PROPERTY_ACQUISITION', label: 'tax.PROPERTY_ACQUISITION' },
  // { value: 'CIVIL', label: 'tax.CIVIL' },
  // { value: 'EXCISE', label: 'tax.EXCISE' },
];

export const taxesPerCountry: Record<ValuationCountryCode, Array<Tax>> = {
  AT: [Tax.NOVA, Tax.VAT],
  AT_LOT: [Tax.NOVA, Tax.VAT],
  DE: [Tax.VAT],
  ES: [Tax.REGISTRATION, Tax.VAT],
  FR: [Tax.ECO, Tax.VAT],
  IT: [Tax.ECOMALUS, Tax.TRANSCRIPTION, Tax.VAT],
  CZ: [Tax.REGISTRATION, Tax.VAT],
  HU: [Tax.AUTHENTICATION, Tax.PROPERTY_ACQUISITION, Tax.REGISTRATION, Tax.VAT],
  PL: [Tax.CIVIL, Tax.EXCISE, Tax.VAT],
  RO: [Tax.VAT],
  SI: [Tax.REGISTRATION, Tax.VAT],
  HR: [Tax.REGISTRATION, Tax.VAT],
  SK: [Tax.VAT],
  PT: [Tax.REGISTRATION, Tax.VAT],
  SE: [Tax.VAT],
  BG: [Tax.VAT],
  MK: [Tax.VAT],
  AL: [Tax.VAT],
  XK: [Tax.VAT],
};

export const sources: Readonly<Option<SourceType>[]> = [
  { value: SourceType.AudiGws, label: 'source.audiGws' },
  { value: SourceType.Autobid, label: 'Autobid' },
  { value: SourceType.Autorola, label: 'Autorola' },
  { value: SourceType.Auto1, label: 'Auto1' },
  { value: SourceType.Okcars, label: 'OK Cars' },
  // { value: SourceType.Vdv3, label: 'VDV3' },
  { value: SourceType.Vwfs, label: 'VWFS' },
  // { value: SourceType.VwfsDeAgb, label: 'VWFS DE Augsburg' },
  // { value: SourceType.VwfsDeFraAudi, label: 'VWFS DE Frankfurt Audi' },
  // { value: SourceType.VwfsDeFraVw, label: 'VWFS DE Frankfurt VW' },
  // { value: SourceType.VwfsDeStg, label: 'VWFS DE Stuttgart' },
  // { value: SourceType.VwfsFr, label: 'VWFS Frankreich' },
  { value: SourceType.VwnGws, label: 'VWN GWS' },
  { value: SourceType.Arval, label: 'ARVAL' },
  { value: SourceType.RealFirstCallOwn, label: 'source.realFirstCallOwn' },
  { value: SourceType.RealFirstCallOther, label: 'source.realFirstCallOther' },
  { value: SourceType.Ucs, label: 'source.ucs' },
  { value: SourceType.Vvj, label: 'SQ1' },
  { value: SourceType.SpotdealsAllmobil, label: 'Spotdeals Allmobil' },
];

export const offerTypes: Readonly<Option[]> = [
  { value: OfferType.All, label: 'filterSearch.offerTypeAll' },
  { value: OfferType.Individual, label: 'filterSearch.offerTypeIndiv' },
  { value: OfferType.Package, label: 'common.package_plural' },
];

export const offerTypesV2: Readonly<Option[]> = [
  { value: OfferType.Individual, label: 'filterSearch.offerTypeIndiv' },
  { value: OfferType.Package, label: 'common.package_plural' },
];

export const buyableStatus: Readonly<Option[]> = [
  { value: BuyableStatus.All, label: 'buyableStatus.all' },
  { value: BuyableStatus.Buyable, label: 'buyableStatus.buyable' },
  { value: BuyableStatus.NonBuyable, label: 'buyableStatus.nonBuyable' },
];

export const buyableStatusV2: Readonly<Option[]> = [
  { value: BuyableStatus.Buyable, label: 'buyableStatus.buyable' },
  { value: BuyableStatus.NonBuyable, label: 'buyableStatus.nonBuyable' },
];

export const promotions: Readonly<Option[]> = [
  { value: 'CLUSTER', label: 'promotions.cluster' },
  { value: 'STAFFELBON', label: 'promotions.cashback' },
  { value: 'DIRECT_OFFER', label: 'promotions.directOffer' },
  { value: 'EA189', label: 'promotions.eA189' },
  { value: 'EXKL.BONUS', label: 'promotions.exclusive' },
  { value: 'FIRST_CALL', label: 'promotions.firstCall' },
  { value: 'REAL_FIRST_CALL', label: 'promotions.realFirstCall' },
  { value: 'GW-KENNZ', label: 'promotions.usedCar' },
  { value: 'HIGHLIGHT', label: 'promotions.highlight' },
  { value: 'VAL.10VVJ', label: 'promotions.10vvj' },
  { value: 'VAL.100VVJ', label: 'promotions.100vvj' },
  { value: 'VAL.40VVJ', label: 'promotions.40vvj' },
  { value: 'VAL.90VTI', label: 'promotions.90vti' },
  { value: 'VAL.90VVJ', label: 'promotions.90vvj' },
  { value: 'TRANSPORT', label: 'promotions.transport' },
  { value: 'VERMIETER', label: 'promotions.rentalCar' },
  { value: 'VOL.BONUS', label: 'promotions.volBonus' },
  { value: 'RESERVEABLE', label: 'promotions.reserveable' },
  { value: 'JEZ2022', label: 'promotions.jez2022' },
  { value: 'VAL.30SVK', label: 'promotions.val30svk' },
  { value: 'VAL.30VTI', label: 'promotions.val30vti' },
  { value: 'VAL.30VVJ', label: 'promotions.val30vvj' },
  { value: 'VAL.40VTI', label: 'promotions.val40vti' },
  { value: 'VOL.B.VTI', label: 'promotions.volb_vti' },
  { value: 'VAL.30SKO', label: 'promotions.val30sko' },
  { value: 'VAL.90SKO', label: 'promotions.val90sko' },
  { value: 'VAL.90SEA', label: 'promotions.val90sea' },
  { value: 'WUI', label: 'promotions.wui' },
  { value: 'TAX_DEDUCTION', label: 'promotions.tax_deduction' },
  { value: 'HAS_PAIN_AGREEMENT', label: 'promotions.pain' },
  { value: 'CASHBACK', label: 'promotions.cashback' },
  { value: 'BONUS_MODELL', label: 'promotions.BONUS_MODELL' },
  { value: 'FOKUS_MODELL', label: 'promotions.FOKUS_MODELL' },
  { value: 'HEISSW_AKTION', label: 'promotions.HEISSW_AKTION' },
];

export const searchAgentSaveModes: Readonly<Option[]> = [
  { value: 'NEW', label: 'filterSearch.newSearchAgent' },
  { value: 'EDIT', label: 'filterSearch.editSearchAgent' },
];

export const validityTypes: Readonly<Option[]> = [
  { value: ValidityType.All, label: 'bids.validityTypes.all' },
  { value: ValidityType.Current, label: 'bids.validityTypes.current' },
  { value: ValidityType.Expired, label: 'bids.validityTypes.expired' },
];

export const validityTypesV2: Readonly<Option[]> = [
  { value: ValidityType.Current, label: 'bids.validityTypes.current' },
  { value: ValidityType.Expired, label: 'bids.validityTypes.expired' },
];

export const bidStatusTypes: Readonly<Option[]> = [
  { value: BidFilterStatus.All, label: 'filterSearch.offerTypeAll' },
  { value: BidFilterStatus.Bidden, label: 'bids.bidHammers.bidden' },
  { value: BidFilterStatus.NotBidden, label: 'bids.bidHammers.notBid' },
  { value: BidFilterStatus.AnybodyBidden, label: 'bids.bidHammers.biddenAnybody' },
  { value: BidFilterStatus.NobodyBidden, label: 'bids.bidHammers.notBiddenAnyone' },
];

export const bidStatusTypesV2: Readonly<Option[]> = [
  { value: BidFilterStatus.Bidden, label: 'bids.bidHammers.bidden' },
  { value: BidFilterStatus.NotBidden, label: 'bids.bidHammers.notBid' },
  { value: BidFilterStatus.AnybodyBidden, label: 'bids.bidHammers.biddenAnybody' },
  { value: BidFilterStatus.NobodyBidden, label: 'bids.bidHammers.notBiddenAnyone' },
];

export const getBidStatusTypes = (roles: UserRole[]) => {
  if (roles.includes(UserRole.ADMIN)) {
    return bidStatusTypesV2;
  }
  return bidStatusTypesV2.filter(
    (type) => type.value !== BidFilterStatus.AnybodyBidden && type.value !== BidFilterStatus.NobodyBidden,
  );
};

export const bidStatusTypesExpired: Readonly<Option[]> = [
  { value: BidFilterStatus.All, label: 'filterSearch.offerTypeAll' },
  { value: BidFilterStatus.Bidden, label: 'bids.bidHammers.expired.bidden' },
  { value: BidFilterStatus.NotBidden, label: 'bids.bidHammers.notBid' },
  { value: BidFilterStatus.AnybodyBidden, label: 'bids.bidHammers.biddenAnybody' },
  { value: BidFilterStatus.NobodyBidden, label: 'bids.bidHammers.notBiddenAnyone' },
];

export const bidStatusTypesExpiredV2: Readonly<Option[]> = [
  { value: BidFilterStatus.Bidden, label: 'bids.bidHammers.expired.bidden' },
  { value: BidFilterStatus.NotBidden, label: 'bids.bidHammers.notBid' },
  { value: BidFilterStatus.AnybodyBidden, label: 'bids.bidHammers.biddenAnybody' },
  { value: BidFilterStatus.NobodyBidden, label: 'bids.bidHammers.notBiddenAnyone' },
];

export const getBidStatusTypesExpired = (roles: UserRole[]) => {
  if (roles.includes(UserRole.ADMIN)) {
    return bidStatusTypesExpiredV2;
  }
  return bidStatusTypesExpiredV2.filter(
    (type) => type.value !== BidFilterStatus.AnybodyBidden && type.value !== BidFilterStatus.NobodyBidden,
  );
};

export const bidSuccessStatusTypes: Readonly<Option[]> = [
  { value: BidSuccessStatus.Highest, label: 'bids.status.highest' },
  { value: BidSuccessStatus.Outbid, label: 'bids.status.outbid' },
  { value: BidSuccessStatus.Sent, label: 'bids.status.sent' },
  { value: BidSuccessStatus.Cancelled, label: 'bids.status.cancelled' },
  { value: BidSuccessStatus.Error, label: 'bids.status.error' },
  { value: BidSuccessStatus.Bought, label: 'bids.status.bought' },
  { value: BidSuccessStatus.Scheduled, label: 'bids.status.scheduled' },
  { value: BidSuccessStatus.Allocate, label: 'bids.status.allocate' },
];

// Default value for the success states (bought & scheduled are not included)
export const bidSuccessStatusFilterable = [
  BidSuccessStatus.Highest,
  BidSuccessStatus.Outbid,
  BidSuccessStatus.Sent,
  BidSuccessStatus.Cancelled,
  BidSuccessStatus.Error,
  BidSuccessStatus.Allocate,
];

export const engineFuels: Readonly<Option[]> = [
  { value: NormalizedFuel.Petrol, label: 'car.engineFuels.petrol' },
  { value: NormalizedFuel.Diesel, label: 'car.engineFuels.diesel' },
  { value: NormalizedFuel.Elektro, label: 'car.engineFuels.electric' },
  { value: NormalizedFuel.Gas, label: 'car.engineFuels.gas' },
  { value: NormalizedFuel.Hybrid, label: 'car.engineFuels.hybrid' },
  { value: NormalizedFuel.Hydrogen, label: 'car.engineFuels.hydrogen' },
  { value: NormalizedFuel.DieselHybrid, label: 'car.engineFuels.diesel_hybrid' },
  { value: NormalizedFuel.PetrolHybrid, label: 'car.engineFuels.petrol_hybrid' },
];

export const transmissions: Readonly<Option[]> = [
  { value: NormalizedTransmission.Manual, label: 'car.equipments.manual' },
  { value: NormalizedTransmission.Automat, label: 'car.equipments.automatic' },
];

export const CarDetailingTypes: Readonly<Option[]> = [
  { value: CarDetailing.NoDetailing, label: 'dealerConfig.carDetailing.noDetailing' },
  { value: CarDetailing.ServicePackage, label: 'dealerConfig.carDetailing.servicePkg' },
  { value: CarDetailing.DetailingPackage, label: 'dealerConfig.carDetailing.detailingPkg' },
  { value: CarDetailing.DetailingPackageDiscounted, label: 'dealerConfig.carDetailing.detailingPkgDiscount' },
  { value: CarDetailing.RepairPackage, label: 'dealerConfig.carDetailing.repairPkg' },
];

export enum PotentialFilterType {
  PotentialInternetPriceMin = 'potentialInternetPriceMin',
  PotentialInternetPriceAverage = 'potentialInternetPriceAverage',
  PotentialInternetPriceMinAverage = 'potentialInternetPriceMinAverage',
}

export const potentialFilters: Readonly<Option<PotentialFilterType>[]> = [
  { value: PotentialFilterType.PotentialInternetPriceMin, label: 'car.prices.potentialPriceMin' },
  { value: PotentialFilterType.PotentialInternetPriceAverage, label: 'car.prices.potentialPriceAverage' },
  { value: PotentialFilterType.PotentialInternetPriceMinAverage, label: 'car.prices.potentialPriceMinAverage' },
];

export const sourceOriginFilter: Readonly<Option<string>[]> = [
  { value: 'NW KZL VW', label: 'carsTableNext.filter.originSources.NW_KZL_VW' },
  { value: 'MIETFZG VW', label: 'carsTableNext.filter.originSources.MIETFZG_VW' },
  { value: 'MIETFZG FS', label: 'carsTableNext.filter.originSources.MIETFZG_FS' },
  { value: 'WA-LEASING', label: 'carsTableNext.filter.originSources.WA-LEASING' },
  { value: 'GFZ VW', label: 'carsTableNext.filter.originSources.GFZ_VW' },
  { value: 'GFZ VW FS', label: 'carsTableNext.filter.originSources.GFZ_VW_FS' },
  { value: 'WANDLUNG', label: 'carsTableNext.filter.originSources.WANDLUNG' },
  {
    value:
      'SEAT,SEAT VERM,SEAT RAC1,SEAT RAC2,SEAT RACN,SEAT GKDIR,SEAT BB,SEAT SIEM,SEAT WANDL,SEAT ESP,SEAT CTL,SEAT TS',
    label: 'carsTableNext.filter.originSources.SEAT',
  },
  { value: 'SAD,SAD WANDL,SAD RAC1,SAD RACN,SAD CTL,SAD TS', label: 'carsTableNext.filter.originSources.SAD' },
  { value: 'SVK B', label: 'carsTableNext.filter.originSources.SVK_B' },
  // { value: '', label: 'carsTableNext.filter.originSources.VTI' }, // Dummy Entry - wait for news
];

export const CARS_TABLE_EXPORT_LABELS: Record<string, string> = {
  rating: 'carsTable.userRating',
  userComment: 'carsTable.userComment',
  dateStart: 'carsTable.startingTime',
  carId: 'car.ID',
  countryOrigin: 'car.countryOrigin',
  colorId: 'colors.color',
  equipmentKeys: 'car.equipment',
  assessmentLink: 'common.assessment',
  priceDamage: 'car.damage',
  damageType: 'car.damageType',
  priceFixed: 'car.prices.fixedPrice',
  priceOrientation: 'car.prices.priceOrientation',
  engineCo2: 'car.co2',
  nova: 'car.nova',
  co2ApproximationMethod: 'car.co2Approx',
  engineTestingProcedure: 'car.co2Testing',
  vin: 'car.vin',
  packageId: 'common.packageId',
  'potential.base': 'car.prices.basePrice',
  'potential.purchaseGross': 'car.prices.purchasePriceGross',
  'potential.potentialMinAbsolute': 'car.prices.potentialMinAbsolute',
  'potential.potentialMinRelative': 'car.prices.potentialMinRelative',
  'potential.potentialMinMeanAbsolute': 'car.prices.potentialMinMeanAbsolute',
  'potential.potentialMinMeanRelative': 'car.prices.potentialMinMeanRelative',
  'potential.potentialMeanAbsolute': 'car.prices.potentialMeanAbsolute',
  'potential.potentialMeanRelative': 'car.prices.potentialMeanRelative',
  'potential.purchaseNetWholesaleUnrepaired': 'car.prices.purchaseNetWholesaleUnrepaired',
  'potential.purchaseNetRetailRepaired': 'car.prices.purchaseNetRetailRepaired',
  'valuation.price.mean': 'car.prices.marketPrice',
  engineCo2Wltp: 'carsTable.engineCo2Wltp',
  engineCo2Nedc: 'carsTable.engineCo2Nedc',
  'normalized.brand': 'car.brand',
  'normalized.model': 'car.model',
  'normalized.equipmentLine': 'car.equipmentLine',
  'normalized.version': 'car.version',
  'normalized.fullModelName': 'car.fullModelName',
  'bids.bid': 'common.bid',
  'bids.buyingTime': 'bids.buyingTime',
  'bids.biddingTime': 'bids.bidHammers.expired.bidden',
  'bids.user.name': 'common.username',
  'bids.dealer.id': 'navigation.dealer',
  'bids.status': '$t(bids.bidStatus) ($t(configuration.individual))',
};

export const CARS_POTENTIAL_PROPERY_LABELS: Record<string, string> = {
  purchaseGross: 'car.prices.purchasePriceGross',
  potentialMinAbsolute: 'car.prices.potentialMinAbsolute',
  potentialMinRelative: 'car.prices.potentialMinRelative',
  potentialMinMeanAbsolute: 'car.prices.potentialMinMeanAbsolute',
  potentialMinMeanRelative: 'car.prices.potentialMinMeanRelative',
  potentialMeanAbsolute: 'car.prices.potentialMeanAbsolute',
  potentialMeanRelative: 'car.prices.potentialMeanRelative',
  purchaseNetWholesaleUnrepaired: 'car.prices.purchaseNetWholesaleUnrepaired',
  purchaseNetRetailRepaired: 'car.prices.purchaseNetRetailRepaired',
};

export enum CarsPotentialTypes {
  potentialMeanAbsolute = 'potentialMeanAbsolute',
  potentialMeanRelative = 'potentialMeanRelative',
  potentialMinAbsolute = 'potentialMinAbsolute',
  potentialMinMeanAbsolute = 'potentialMinMeanAbsolute',
  potentialMinMeanRelative = 'potentialMinMeanRelative',
  potentialMinRelative = 'potentialMinRelative',
  purchaseGross = 'purchaseGross',
  purchaseNetRetailRepaired = 'purchaseNetRetailRepaired',
  purchaseNetRetailUnrepaired = 'purchaseNetRetailUnrepaired',
}

export const getPropertyLabel = (property: string, forceLanguage?: SupportedLanguage) => {
  const key =
    CARS_BIDS_TABLE_COLUMNS[property as CarTableColumn]?.label || CARS_TABLE_EXPORT_LABELS[property] || property;

  const propertyLabel = forceLanguage ? i18n.getFixedT(forceLanguage)(key) : tr(key);
  if (!propertyLabel) return property;

  return propertyLabel;
};

export const conditionalFormattingTypes: Readonly<Option[]> = [
  { value: ConditionalFormattingType.Model, label: 'car.model' },
  { value: ConditionalFormattingType.FirstRegistration, label: 'car.firstRegistration_lng' },
  { value: ConditionalFormattingType.Mileage, label: 'units.km_brace', options: { target: 'car.mileage' } },
  {
    value: ConditionalFormattingType.NormalizedFuel,
    label: 'carsTableNext.columns.normalized.fuel',
  },
  { value: ConditionalFormattingType.PriceDamage, label: 'units.euro_brace', options: { target: 'car.damage2' } },
  { value: ConditionalFormattingType.DamageType, label: 'car.damageType' },
  { value: ConditionalFormattingType.Nova, label: 'units.percent_brace', options: { target: 'car.nova' } },
  { value: ConditionalFormattingType.NumAuctions, label: 'car.numAuctions' },
  {
    value: ConditionalFormattingType.DateOnlineTimeInMinutes,
    label: 'units.minutes_brace',
    options: { target: 'car.onlineTime' },
  },
  {
    value: ConditionalFormattingType.PotentialBase,
    label: 'units.euro_brace',
    options: { target: 'car.prices.basePrice' },
  },
  {
    value: ConditionalFormattingType.PurchaseNetUnrepaired,
    label: 'units.euro_brace',
    options: { target: 'car.prices.purchaseNetWholesaleUnrepaired' },
  },
  {
    value: ConditionalFormattingType.PurchaseNetRepaired,
    label: 'units.euro_brace',
    options: { target: 'car.prices.purchaseNetRetailRepaired' },
  },
  {
    value: ConditionalFormattingType.PurchaseGross,
    label: 'units.euro_brace',
    options: { target: 'car.prices.purchaseGross' },
  },
  {
    value: ConditionalFormattingType.ValuationMarketPrice,
    label: 'units.euro_brace',
    options: { target: 'car.prices.marketPrice' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceMinAbsolute,
    label: 'units.euro_brace',
    options: { target: 'car.prices.potentialMinAbsolute' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceMinPercent,
    label: 'units.percent_brace',
    options: { target: 'car.prices.potentialMinRelative' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceMinAverageAbsolute,
    label: 'units.euro_brace',
    options: { target: 'car.prices.potentialMinMeanAbsolute' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceMinAveragePercent,
    label: 'units.percent_brace',
    options: { target: 'car.prices.potentialMinMeanRelative' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceAverageAbsolute,
    label: 'units.euro_brace',
    options: { target: 'car.prices.potentialMeanAbsolute' },
  },
  {
    value: ConditionalFormattingType.PotentialInternetPriceAveragePercent,
    label: 'units.percent_brace',
    options: { target: 'car.prices.potentialMeanRelative' },
  },
  {
    value: ConditionalFormattingType.Co2Nefz,
    label: 'units.gram_brace',
    options: { target: 'car.co2Nefz' },
  },
  {
    value: ConditionalFormattingType.Co2Wltp,
    label: 'units.gram_brace',
    options: { target: 'car.co2Wltp' },
  },
  { value: ConditionalFormattingType.TaxType, label: 'carsTable.taxationType' },
  { value: ConditionalFormattingType.Vat, label: 'units.percent_brace', options: { target: 'taxTypes.vat' } },
  { value: ConditionalFormattingType.Eco, label: 'units.euro_brace', options: { target: 'taxTypes.eco' } },
  { value: ConditionalFormattingType.Ecomalus, label: 'units.euro_brace', options: { target: 'taxTypes.ecomalus' } },
  {
    value: ConditionalFormattingType.Transcription,
    label: 'units.euro_brace',
    options: { target: 'taxTypes.transcription' },
  },
  {
    value: ConditionalFormattingType.RegistrationAbsolute,
    label: 'units.euro_brace',
    options: { target: 'taxTypes.registration' },
  },
  {
    value: ConditionalFormattingType.RegistrationRelative,
    label: 'units.percent_brace',
    options: { target: 'taxTypes.registration' },
  },
  {
    value: ConditionalFormattingType.Authentication,
    label: 'units.euro_brace',
    options: { target: 'taxTypes.authentication' },
  },
  {
    value: ConditionalFormattingType.PropertyAcquisition,
    label: 'units.euro_brace',
    options: { target: 'taxTypes.property_acquisition' },
  },
  { value: ConditionalFormattingType.Civil, label: 'units.percent_brace', options: { target: 'taxTypes.civil' } },
  { value: ConditionalFormattingType.Excise, label: 'units.percent_brace', options: { target: 'taxTypes.excise' } },
  { value: ConditionalFormattingType.Promotions, label: 'filterSearch.promotions' },
];

export const conditionalFormattingColors: Readonly<Option[]> = [
  { value: red[100], label: 'colors.red' },
  { value: orange[100], label: 'colors.orange' },
  { value: yellow[100], label: 'colors.yellow' },
  { value: green[100], label: 'colors.green' },

  { value: pink[100], label: 'colors.pink' },
  { value: purple[100], label: 'colors.purple' },
  { value: deepPurple[100], label: 'colors.deepPurple' },
  { value: indigo[100], label: 'colors.indigo' },
  { value: blue[100], label: 'colors.blue' },
  { value: lightBlue[100], label: 'colors.lightBlue' },
  { value: cyan[100], label: 'colors.cyan' },
  { value: teal[100], label: 'colors.teal' },
  { value: lightGreen[100], label: 'colors.lightGreen' },
  { value: lime[100], label: 'colors.lime' },
  { value: amber[100], label: 'colors.amber' },
  { value: deepOrange[100], label: 'colors.deepOrange' },
  { value: brown[100], label: 'colors.brown' },
  { value: grey[100], label: 'colors.grey' },
  { value: blueGrey[100], label: 'colors.blueGrey' },
];

export const REGIONS_LABELS: Readonly<{ value: ValuationCountryCode; label: string }[]> = [
  { value: ValuationCountryCode.Fr, label: 'regions.FR' },
  { value: ValuationCountryCode.At, label: 'regions.AT' },
  { value: ValuationCountryCode.AtLot, label: 'regions.AT-LOT' },
];

// Zwischenlösung solange es AT_Lot und AT_CC gbit, ENUM aus BE nicht änderbar
export const SHORT_REGIONS_LABELS: Readonly<{ value: ValuationCountryCode; label: string }[]> = [
  { value: ValuationCountryCode.Fr, label: 'FR' },
  { value: ValuationCountryCode.AtLot, label: 'AT' },
  { value: ValuationCountryCode.At, label: 'AT_CC' },
  { value: ValuationCountryCode.De, label: 'DE' },
];

export const TAXTYPES: Readonly<{ value: TaxType; label: string }[]> = [
  { value: TaxType.Regelbesteuert, label: 'carsTable.regularilyTaxed' },
  { value: TaxType.Differenzbesteuert, label: 'carsTable.differentialTaxation' },
];

export const countryCodeToOption = (countryCode: string, lang: string): Option => ({
  value: countryCode,
  label: countries.getName(countryCode, lang) || getLabel(REGIONS_LABELS, countryCode),
});

export const countryCodeToRegion = (countryCode: string, lang: string): Option => ({
  value: countryCode,
  label: getLabel(REGIONS_LABELS, countryCode) || countries.getName(countryCode, lang),
});

export const countryCodeToOptions = (countryCodes: Array<string>, lang: string): Option[] =>
  countryCodes.map((countryCode) => countryCodeToOption(countryCode, lang) || countryCodeToRegion(countryCode, lang));

export const countryCodeToRegions = (countryCodes: Array<string>, lang: string): Option[] =>
  countryCodes.map((countryCode) => countryCodeToRegion(countryCode, lang) || countryCodeToOption(countryCode, lang));

// export const getVwfsDetailsLong = (source: SourceType): string => {
//   const sourceAddressMap: { [x in SourceType]?: string } = {
//     [SourceType.VwfsDeAgb]: 'VWFS DEUV27928 Augsburg Donaustrasse 8',
//     [SourceType.VwfsDeStg]: 'VWFS DEUV26603 Stuttgart Heilbronner Straße 340',
//     [SourceType.VwfsDeFraAudi]: 'VWFS DEUA21239 Frankfurt Landstraße 144',
//     [SourceType.VwfsDeFraVw]: 'VWFS DEUV21232 Frankfurt Mainzer Landstraße 406',
//     [SourceType.VwfsFr]: 'VWFS FRA80630',
//   };

//   return sourceAddressMap[source] ?? source;
// };

// export const sourceToKVPS = (source: SourceType): string => {
//   const sourceKVPSMap: { [x in SourceType]?: string } = {
//     [SourceType.VwfsDeAgb]: 'DEUV27928',
//     [SourceType.VwfsDeFraAudi]: 'DEUA21239',
//     [SourceType.VwfsDeFraVw]: 'DEUV21232',
//     [SourceType.VwfsDeStg]: 'DEUV26603',
//     [SourceType.VwfsFr]: 'FRA80630',
//   };

//   return sourceKVPSMap[source] ?? '';
// };

export const getRegionOptions = () => [
  { value: 'default', label: 'Global' },
  ...countryCodeToRegions(SUPPORTED_COUNTRIES, 'de'),
];

export const USERNAME_STATUS_LABELS: Readonly<{ value: UsernameStatusBasic; label: string }[]> = [
  { value: UsernameStatusBasic.Disabled, label: 'common.inactive' },
  { value: UsernameStatusBasic.Pending, label: 'common.pending' },
  { value: UsernameStatusBasic.Enabled, label: 'common.active' },
];

export const TERMS_AND_CONDITIONS_LABELS: Readonly<Option[]> = [
  {
    value: TermsConditionsType.VwfsTermsConditions,
    label: 'termsAndConditions.vwfs_suffix',
    options: { suffix: 'termsAndConditions.termsAndConditions' },
  },
  {
    value: TermsConditionsType.TermsConditions,
    label: 'termsAndConditions.iris_suffix',
    options: { suffix: 'termsAndConditions.termsAndConditions' },
  },
  {
    value: TermsConditionsType.VwfsPrivacy,
    label: 'termsAndConditions.vwfs_suffix',
    options: { suffix: 'termsAndConditions.privacy' },
  },
  {
    value: TermsConditionsType.GwsTermsConditions,
    label: 'termsAndConditions.gws_suffix',
    options: { suffix: 'termsAndConditions.termsAndConditions' },
  },
  {
    value: TermsConditionsType.AudiGwsTermsConditions,
    label: 'termsAndConditions.audi_suffix',
    options: { suffix: 'termsAndConditions.termsAndConditions' },
  },
  {
    value: TermsConditionsType.VwnGwsTermsConditions,
    label: 'termsAndConditions.vwn_suffix',
    options: { suffix: 'termsAndConditions.termsAndConditions' },
  },
];
export type PriceStrategyType =
  | PriceType.PotentialMeanRelative
  | PriceType.PotentialMinMeanRelative
  | PriceType.PotentialMinRelative;
export const PRICE_STRATEGY_LABEL: Record<PriceStrategyType, string> = {
  [PriceType.PotentialMeanRelative]: 'carsTable.priceStrategies.meanMarket',
  [PriceType.PotentialMinMeanRelative]: 'carsTable.priceStrategies.minMeanMarket',
  [PriceType.PotentialMinRelative]: 'carsTable.priceStrategies.minMarket',
};

export const recordsToOptions = (records: Record<any, string>): Option[] =>
  Object.entries(records).map(([key, value]) => ({
    label: value,
    value: key,
  }));

export const formattingScopes: Option[] = [
  { value: ConditionalFormattingScope.Cell, label: 'conditionalFormatting.scope.cell' },
  { value: ConditionalFormattingScope.Row, label: 'conditionalFormatting.scope.row' },
];

export const searchAgentRoleOptions: Option<SearchAgentRole>[] = [
  { label: 'searchAgentRoles.NONE', value: SearchAgentRole.None },
  { label: 'searchAgentRoles.SHOPPING_CART', value: SearchAgentRole.ShoppingCart },
];

export const formatDeliveryAddress = (deliveryAddress: DeliveryAddressDTO) => {
  const info = [
    deliveryAddress.type === DeliveryAddressType.PlatformStandard
      ? tr('addressCard.platformStandard.title')
      : deliveryAddress.contactPersonName,
    deliveryAddress.street,
    deliveryAddress.zip,
    deliveryAddress.city,
  ].filter((element) => element !== undefined);
  return takeRight(info, 3).join(', ');
};

export const bidVisibilityTypes: Option<BidVisibility>[] = [
  { value: BidVisibility.User, label: 'bids.user' },
  { value: BidVisibility.Dealer, label: 'bids.retailer' },
  { value: BidVisibility.Country, label: 'contact.country' },
  { value: BidVisibility.All, label: 'common.all' },
];

export const biddingTimeTypes: Option<DateFilter>[] = [
  { value: DateFilter.All, label: 'common.all' },
  { value: DateFilter.ThisMonth, label: 'carsTable.thisMonth' },
  { value: DateFilter.LastMonth, label: 'carsTable.lastMonth' },
  { value: DateFilter.Individual, label: 'configuration.individual' },
];
