export enum QueryKeys {
  searchAgents = '/api/cars/search-agent',
  externalSearchAgents = '/api/cars/search-agent/external',
  searchAgentPreview = '/api/cars/search-agent-preview',
  carsList = '/api/cars/list',
  bidsList = '/api/bids-new/list',
  carDetail = '/api/cars/{source}/{id}',
  conditionalFormats = '/api/config/conditional-formatting',
  deliveryAddresses = '/api/dealer/delivery-addresses',
  sources = '/api/sources',
  currentUser = '/api/auth/me',
  filterSummary = '/api/cars/list/summary',
  bidsFilterSummary = '/api/bids-new/summary',
  bid = '/api/cars/bids',
  exchangeRates = '/api/calculation/exchange-rates',
  allCategory = '/api/content/categories',
  category = '/api/content/category',
  allFaq = '/api/content/faqs',
  faq = '/api/content/faq',
  myDealer = '/api/dealer/me',
  servicePackets = '/api/packet-service/packets',
  servicePacket = '/api/packet-service/packet',
}

export enum CacheKeys {
  searchAgentsOrder = 'gwscout/SearchAgents.order',
  tableNextSidebarOpen = 'gwscout/TableNext.sidebarOpen',
  carsTableNextColumnOrder = 'gwscout/CarsTableNext.columnOrder',
  carsTableNextColumnVisibility = 'gwscout/CarsTableNext.columnVisibility',
  carsTableNextColumnSizing = 'gwscout/CarsTableNext.columnSizing',
  carsTableNextColumnPinning = 'gwscout/CarsTableNext.columnPinning',
  carsTableNextPagination = 'gwscout/CarsTableNext.pagination',
  carsTableNextPageSize = 'gwscout/CarsTableNext.pageSize',
  carsTableNextSorting = 'gwscout/CarsTableNext.sorting',
  carsTableNextFilter = 'gwscout/CarsTableNext.filter',
  carsTableNextFilterExpanded = 'gwscout/CarsTableNext.filterExpanded',
  carsTableNextSidebarExpanded = 'gwscout/CarsTableNext.sidebarExpanded',
  carsTableNextRefetchInterval = 'gwscout/CarsTableNext.refetchInterval',

  bidsTableNextSorting = 'gwscout/BidsTableNext.sorting',

  bidsPendingTableNextColumnOrder = 'gwscout/PendingBidsTableNext.columnOrder',
  bidsPendingTableNextColumnVisibility = 'gwscout/PendingBidsTableNext.columnVisibility',
  bidsPendingTableNextColumnSizing = 'gwscout/PendingBidsTableNext.columnSizing',
  bidsPendingTableNextColumnPinning = 'gwscout/PendingBidsTableNext.columnPinning',
  bidsPendingTableNextFilter = 'gwscout/PendingBidsTableNext.filter',
  bidsPendingTableNextFilterExpanded = 'gwscout/PendingBidsTableNext.filterExpanded',

  bidsPastTableNextColumnOrder = 'gwscout/PastBidsTableNext.columnOrder',
  bidsPastTableNextColumnVisibility = 'gwscout/PastBidsTableNext.columnVisibility',
  bidsPastTableNextColumnSizing = 'gwscout/PastBidsTableNext.columnSizing',
  bidsPastTableNextColumnPinning = 'gwscout/PastBidsTableNext.columnPinning',
  bidsPastTableNextFilter = 'gwscout/PastBidsTableNext.filter',
  bidsPastTableNextFilterExpanded = 'gwscout/PastBidsTableNext.filterExpanded',

  bidsOrderedTableNextColumnOrder = 'gwscout/OrderedBidsTableNext.columnOrder',
  bidsOrderedTableNextColumnVisibility = 'gwscout/OrderedBidsTableNext.columnVisibility',
  bidsOrderedTableNextColumnSizing = 'gwscout/OrderedBidsTableNext.columnSizing',
  bidsOrderedTableNextColumnPinning = 'gwscout/OrderedBidsTableNext.columnPinning',
  bidsOrderedTableNextFilter = 'gwscout/OrderedBidsTableNext.filter',
  bidsOrderedTableNextFilterExpanded = 'gwscout/OrderedBidsTableNext.filterExpanded',

  ordersTableSidebar = 'gwscout/Orders.sidebarOpen',
  activeCurrency = 'gwscout/i18n.activeCurrency',
  headerState = 'header/headerOpen',
}

export enum RoutePaths {
  login = '/login',
  logout = '/logout',
  forbidden = 'forbidden',
  cars = '/cars',
  car = '/cars/:source/:carId',
  searchAgents = '/searchagents',
  experiments = '/settings/experiments',
  termsAndConditions = '/terms-and-conditions',
  imprint = '/imprint',
  orders = '/orders',
  bidsPending = '/bids/pending',
  bidsPast = '/bids/past',
  dealers = '/settings/dealers',
  costs = '/settings/costs',
  credentials = '/settings/credentials',
  formats = '/settings/formats',
  deliveryAddress = '/settings/delivery-address',
  detailingAddress = '/settings/detailing-address',
  help = '/help',
  packets = '/settings/packets',
  imageExport = '/services/image-export/:vin',
}

export const AlgoliaProjectId = 'YH3HMOHU9E';

export const AlgoliaClientKey = '8e46eefec7fc7400fc5a95bea6f052c0';
