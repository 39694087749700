import React from 'react';
import { Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import { navigate } from '@reach/router';
import { Cancel, OpenInNew, Save } from '@material-ui/icons';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import { RoutePaths } from '../constants';
import { DeliveryAddressDTO } from '../modules/generated/api';

type DeliveryAddressFormProps = {
  deliveryAddresses: DeliveryAddressDTO[];
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  methods: UseFormReturn<{ deliveryAddressId: string | undefined }>;
  showLink?: boolean;
};

const DeliveryAddressForm = ({
  onCancel,
  onSubmit,
  isLoading,
  methods,
  deliveryAddresses = [],
  showLink,
}: DeliveryAddressFormProps) => {
  const { t } = useTranslation();
  return (
    <FormProvider {...methods}>
      <Grid container spacing={1}>
        <Grid item container alignItems="center">
          <Grid item xs={10}>
            <DeliveryAddressSelect
              name="deliveryAddressId"
              deliveryAddresses={deliveryAddresses}
              control={methods.control}
            />
          </Grid>
          {showLink && (
            <Grid item xs={2}>
              <IconButton onClick={() => navigate(RoutePaths.deliveryAddress)}>
                <OpenInNew />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid container item spacing={1}>
          <Grid item>
            <Button color="secondary" disabled={isLoading} endIcon={<Cancel />} onClick={onCancel} variant="contained">
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={isLoading}
              endIcon={isLoading ? <CircularProgress size={20} /> : <Save />}
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default DeliveryAddressForm;
