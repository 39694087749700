import { Alert, AlertTitle } from '@material-ui/lab';
import { ReactElement, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BidBuyErrors } from '../../modules/bid-buy-helpers';
import { DetailCarDTO } from '../../modules/generated/api';
import { countryCodeToRegion } from '../../modules/labels';
import { formatUnitValue } from '../../modules/unit-helpers';
import { getAccountValuationCountry } from '../../modules/valuation-country-helpers';
import i18n from '../../setup/i18n';

type BidAlertsProps = {
  className?: string;
  car: DetailCarDTO;
  errors: BidBuyErrors;
  onlyBuying: boolean;
};

export const BidAlerts = ({ className, car, errors, onlyBuying }: BidAlertsProps): ReactElement => {
  const { t } = useTranslation();
  // Warnings do not lock submit

  // As a quickfix for ticket IRIS-3947, we are removing the colleagueBidActive warning for now 
  // const warnings = omitBy({ colleagueBidActive: !!car.bidStateInfo?.highestHistoricBid?.amount }, (pred) => !pred);

  const messages: Record<keyof BidBuyErrors, ReactNode> = {
    dealerAddressNotSet: t('bids.mustVerifyDeliveryAddress'),
    colleagueBidActive: (
      <Trans
        t={t}
        i18nKey="alerts.prevBid"
        values={{
          minBid: formatUnitValue((car.bidStateInfo?.highestHistoricBid?.amount || 0) + 100, '€'),
        }}
      />
    ),
    credentialsMissing: t('alerts.missingCred'),
    illegalAccountValuationCountry: t('bids.functionBuyNotAvailable', {
      country: countryCodeToRegion(getAccountValuationCountry(), i18n.language).label,
    }),
  };

  const removeMessage = () => {
    if (onlyBuying) {
      delete messages.colleagueBidActive;
    }
  };
  removeMessage();

  return (
    <>
      {/* Errors */}
      {Object.keys(errors).map((key) => (
        <Alert key={key} severity="error" className={className}>
          <AlertTitle>{t('bids.status.error')}</AlertTitle>
          {messages[key as keyof BidBuyErrors]}
        </Alert>
      ))}
      {/* Warnings */}
      {/* {Object.keys(warnings).map((key) => (
        <Alert key={key} severity="warning" className={className}>
          <AlertTitle>{t('alerts.confirmBidTitle')}</AlertTitle>
          {messages[key as keyof BidBuyErrors]}
        </Alert>
      ))} */}
    </>
  );
};
