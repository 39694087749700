export type FeatureFlags = {
  [key: string]: {
    development: boolean;
    qa: boolean;
    production: boolean;
  }
};

export const flagsConfig: FeatureFlags = {
  "setAutoBidOnSubsequentOffers": {
    development: true,
    qa: true,
    production: true,
  }
};
