import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../hooks/useApi';
import ApiService from '../modules/api-service';
import { BidDataTablesOutputListBidDTO, BidSuccessStatus, DetailCarDTO } from '../modules/generated/api';
import BoughtCarDeliveryAddress from './BoughtCarDeliveryAddress';

const useStyles = makeStyles((theme) => ({
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
}));

type CarDetailBoughtCarDeliveryAddressProps = {
  car: DetailCarDTO;
};
const CarDetailBoughtCarDeliveryAddress = ({ car }: CarDetailBoughtCarDeliveryAddressProps) => {
  const [bid, setBid] = useState(car.bid);
  const classes = useStyles();
  const { fetch } = useApi<BidDataTablesOutputListBidDTO>();
  const { t } = useTranslation();

  const handleSearchBid = async () => {
    if (isUndefined(car.vin)) return;
    const response = await fetch(
      ApiService.newBids.bidNewControllerList({
        carsRequest: { carIds: [car.vin] },
      }),
    );
    const queriedBid = response?.data?.data?.[0];
    if (queriedBid) setBid(queriedBid);
  };

  useEffect(() => {
    setBid(car.bid);
  }, [car]);

  return (
    <Paper elevation={3} className={classes.tiles}>
      {bid?.id && (
        <BoughtCarDeliveryAddress
          bidId={bid.id}
          changeDeliveryAddressUntil={bid.deliveryAddressChangeUntil}
          deliveryAddress={bid.deliveryAddress}
          deliveryAddressSelection={car.deliveryAddressSelection}
          winningBid={bid.bids?.find((detailedBid) => detailedBid.status === BidSuccessStatus.Bought)}
        />
      )}
      {!bid && (
        <div>
          <Typography variant="h2">{t('navigation.delivery')} </Typography>

          <p>{t('alerts.loadDeliveryAddress')}</p>
          <div className={classes.center}>
            <Button onClick={handleSearchBid}>
              <Refresh /> {t('common.loadMore')}
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default CarDetailBoughtCarDeliveryAddress;
